// import React from 'react';
// import img1 from '../../Assets/Aboutus/Awards1.png'
// import img2 from '../../Assets/Aboutus/Awards2.png'
// import img3 from '../../Assets/Aboutus/Awards3.png'
// const Awards = () => {
//     return (
//         <div className="px-16 flex space-x-10">
//             <div className="w-[50%]">
//                 <div className="flex justify-center gap-3">

//                     <div className='w-[50%] space-y-3'>
//                         <img
//                             className="w-full"
//                             src={img1}
//                             alt="Trophy"
//                         />
//                         <img
//                             className="w-full"
//                             src={img2}
//                             alt="Trophy"
//                         />
//                     </div>
//                     <div className='w-[50%]'>
//                     <img
//                             className="w-full h-full"
//                             src={img3}
//                             alt="Trophy"
//                         />
//                     </div>
//                 </div>
//             </div>
//             <div className='w-[50%]'>
//                 <h2 className="text-4xl font-bold mb-4">Awards & Certification</h2>
//                 <p className="text-lg text-[#666666]">
//                     The founder and chairman of the company Mr. Sudhir Kumar Jain has been awarded as the
//                     Eminent Business Man of the Year by District Merchant Association in the year 2012
//                     for his unwavering devotion to the company. While Mrs. Lalita Jain, the Managing Partner
//                     of the company has been awarded as the Female Entrepreneur of the SME Segment by
//                     the District Industrial Centre in the year 2014 for her commitment to the Industry.
//                 </p>
//                 <ul className="list-disc pl-8 text-[#1A1A1A] text-xl mt-4">
//                     <li className="mb-2">
//                         To maintain and increase quality at every stage of the production life cycle.
//                     </li>
//                     <li className="mb-2">
//                         To educate and train staff regarding quality.
//                     </li>
//                     <li className="mb-2">
//                         To promote a quality-oriented work culture inside the organisation.
//                     </li>
//                     <li className="mb-2">
//                         To deliver best-in-class products of the highest quality, consistently.
//                     </li>
//                 </ul>
//             </div>
//         </div>
//     );
// };

// export default Awards;



// import React from 'react';
// import img1 from '../../Assets/Aboutus/Awards1.png';
// import img2 from '../../Assets/Aboutus/Awards2.png';
// import img3 from '../../Assets/Aboutus/Awards3.png';

// const Awards = () => {
//   return (
//     <div className="px-4 md:px-8 lg:px-16 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-10">
//       <div className="w-full md:w-1/2">
//         <div className="flex flex-col md:flex-row justify-center gap-3">
//           <div className="w-full md:w-1/2 space-y-3">
//             <img className="w-full" src={img1} alt="Trophy" />
//             <img className="w-full" src={img2} alt="Trophy" />
//           </div>
//           <div className="w-full md:w-1/2">
//             <img className="w-full h-full" src={img3} alt="Trophy" />
//           </div>
//         </div>
//       </div>
//       <div className="w-full md:w-1/2">
//         <h2 className="text-2xl md:text-4xl font-bold mb-4">
//           Awards & Certification
//         </h2>
//         <p className="text-base md:text-lg text-[#666666]">
//           The founder and chairman of the company Mr. Sudhir Kumar Jain has
//           been awarded as the Eminent Business Man of the Year by District
//           Merchant Association in the year 2012 for his unwavering devotion to
//           the company. While Mrs. Lalita Jain, the Managing Partner of the
//           company has been awarded as the Female Entrepreneur of the SME
//           Segment by the District Industrial Centre in the year 2014 for her
//           commitment to the Industry.
//         </p>
//         <ul className="list-disc pl-8 text-[#1A1A1A] text-base md:text-xl mt-4">
//           <li className="mb-2">
//             To maintain and increase quality at every stage of the production
//             life cycle.
//           </li>
//           <li className="mb-2">
//             To educate and train staff regarding quality.
//           </li>
//           <li className="mb-2">
//             To promote a quality-oriented work culture inside the organisation.
//           </li>
//           <li className="mb-2">
//             To deliver best-in-class products of the highest quality,
//             consistently.
//           </li>
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Awards;



import React from 'react'; 
import img1 from '../../Assets/Aboutus/Awards1.png'
import img2 from '../../Assets/Aboutus/Awards2.png'
import img3 from '../../Assets/Aboutus/Awards3.png'

const Awards = () => {
    return (
        <div className="px-4 md:px-16 flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-10">
            <div className="w-full md:w-[50%]">
                <div className="flex flex-col md:flex-row justify-center gap-3">
                    <div className='w-full md:w-[50%] space-y-3'>
                        <img
                            className="w-full"
                            src={img1}
                            alt="Trophy"
                        />
                        <img
                            className="w-full"
                            src={img2}
                            alt="Trophy"
                        />
                    </div>
                    <div className='w-full md:w-[50%]'>
                        <img
                            className="w-full h-full"
                            src={img3}
                            alt="Trophy"
                        />
                    </div>
                </div>
            </div>
            <div className='w-full md:w-[50%]'>
                <h2 className="text-2xl md:text-4xl font-bold mb-4">Awards & Certification</h2>
                <p className="text-base md:text-lg text-[#666666]">
                    The founder and chairman of the company Mr. Sudhir Kumar Jain has been awarded as the
                    Eminent Business Man of the Year by District Merchant Association in the year 2012
                    for his unwavering devotion to the company. While Mrs. Lalita Jain, the Managing Partner
                    of the company has been awarded as the Female Entrepreneur of the SME Segment by
                    the District Industrial Centre in the year 2014 for her commitment to the Industry.
                </p>
                <ul className="list-disc pl-8 text-[#1A1A1A] text-base md:text-xl mt-4">
                    <li className="mb-2">
                        To maintain and increase quality at every stage of the production life cycle.
                    </li>
                    <li className="mb-2">
                        To educate and train staff regarding quality.
                    </li>
                    <li className="mb-2">
                        To promote a quality-oriented work culture inside the organisation.
                    </li>
                    <li className="mb-2">
                        To deliver best-in-class products of the highest quality, consistently.
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Awards;
