import React from "react";
import NotFound from "../Screens/Common/NotFound";
import Home from "../Screens/User/Home";
import About from "../Screens/User/About";
import Contact from "../Screens/User/Contact";
import Blogs from "../Screens/User/Blogs";
import Products from "../Screens/User/Products";

const userRoutes = [
  { path: "/", component: <Home /> },
  { path: "/about", component: <About /> },
  { path: "/contact", component: <Contact /> },
  { path: "/products", component: <Products /> },
  { path: "/blogs", component: <Blogs /> },
  { path: "*", component: <NotFound /> },
];

export { userRoutes };
