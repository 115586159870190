// import React from 'react';
// import img1 from '../../Assets/Aboutus/Behavior.png'
// import img2 from '../../Assets/Aboutus/Growth.png'
// import img3 from '../../Assets/Aboutus/Eco.png'
// import img4 from '../../Assets/Aboutus/Chemical.png'
// import img from '../../Assets/Aboutus/img.png'

// const WhyChooseUs = () => {
//     return (
//         <div className='px-4 md:px-8 lg:px-16 flex flex-col md:flex-row md:gap-14 mt-8'>
//             <div className='w-full md:w-[60%] flex flex-wrap gap-4'>
//                 <div className='flex space-x-10'>
//                     <div className="max-w-sm mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl border border-gray-200">
//                         <div className="p-6">
//                             <div className="flex mb-4">
//                                 <div className="bg-yellow-200 p-4 rounded-md">
//                                   <img src={img1} alt='img'/>
//                                 </div>
//                             </div>
                           
//                             <h2 className="text-lg font-semibold text-gray-800 text-start mb-2">
//                                 Excellent Behavior
//                             </h2>
//                             <p className="text-gray-600 text-start">
//                                 We believe in treating our customers with respect. We always thrive
//                                 to cater to their needs.
//                             </p>
//                         </div>
//                     </div>
//                     <div className="max-w-sm mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl border border-gray-200">
//                         <div className="p-6">
                    
//                             <div className="flex mb-4">
//                                 <div className="bg-yellow-200 p-4 rounded-full">
//                                 <img src={img2} alt='img'/>
//                                 </div>
//                             </div>
//                             <h2 className="text-lg font-semibold text-gray-800 text-start mb-2">
//                                 Excellent Behavior
//                             </h2>
                          
//                             <p className="text-gray-600 text-start">
//                                 We believe in treating our customers with respect. We always thrive
//                                 to cater to their needs.
//                             </p>
//                         </div>
//                     </div>
//                 </div>

//                 <div className='flex space-x-10'>
//                       <div className="max-w-sm mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl border border-gray-200">
//                         <div className="p-6">
//                             <div className="flex mb-4">
//                                 <div className="bg-yellow-200 p-4 rounded-full">
//                                 <img src={img3} alt='img'/>
//                                 </div>
//                             </div>
//                             <h2 className="text-lg font-semibold text-gray-800 text-start mb-2">
//                                 Excellent Behavior
//                             </h2>
//                             <p className="text-gray-600 text-start">
//                                 We believe in treating our customers with respect. We always thrive
//                                 to cater to their needs.
//                             </p>
//                         </div>
//                     </div>
//                     <div className="max-w-sm mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl border border-gray-200">
//                         <div className="p-6">
//                             <div className="flex mb-4">
//                                 <div className="bg-yellow-200 p-4 rounded-full">
//                                 <img src={img4} alt='img'/>
//                                 </div>
//                             </div>
//                             <h2 className="text-lg font-semibold text-gray-800 text-start mb-2">
//                                 Excellent Behavior
//                             </h2>
//                             <p className="text-gray-600 text-start">
//                                 We believe in treating our customers with respect. We always thrive
//                                 to cater to their needs.
//                             </p>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className='w-full md:w-[40%] flex justify-center relative'>
//                 <img src={img} alt='img' className='w-[80%] md:w-[90%]' />
//                 <div className="absolute bottom-4 left-10 text-white">
//                     <h3 className="text-2xl font-bold">250+ Distributor Franchises in Odisha</h3>
//                 </div>

//             </div>
//         </div>
//     );
// };

// export default WhyChooseUs;


import React from 'react';
import img1 from '../../Assets/Aboutus/Behavior.png';
import img2 from '../../Assets/Aboutus/Growth.png';
import img3 from '../../Assets/Aboutus/Eco.png';
import img4 from '../../Assets/Aboutus/Chemical.png';
import img from '../../Assets/Aboutus/img.png';

const WhyChooseUs = () => {
    return (
        <div className='px-4 md:px-8 lg:px-16 flex flex-col lg:flex-row lg:gap-14 mt-6'>
            
            <div className='w-full lg:w-[60%] flex flex-wrap gap-8 justify-center'>
               
                <div className='w-full sm:w-[48%] lg:w-[45%] bg-white rounded-xl shadow-md border border-gray-200'>
                    <div className='p-6'>
                        <div className='flex mb-4'>
                            <div className='bg-yellow-200 p-4 rounded-md'>
                                <img src={img1} alt='img' />
                            </div>
                        </div>
                        <h2 className='text-lg font-semibold text-gray-800 text-center sm:text-start mb-2'>
                            Excellent Behavior
                        </h2>
                        <p className='text-gray-600 text-center sm:text-start'>
                            We believe in treating our customers with respect. We always thrive
                            to cater to their needs.
                        </p>
                    </div>
                </div>
              
                <div className='w-full sm:w-[48%] lg:w-[45%] bg-white rounded-xl shadow-md border border-gray-200'>
                    <div className='p-6'>
                        <div className='flex mb-4'>
                            <div className='bg-yellow-200 p-4 rounded-md'>
                                <img src={img2} alt='img' />
                            </div>
                        </div>
                        <h2 className='text-lg font-semibold text-gray-800 text-center sm:text-start mb-2'>
                            Excellent Growth
                        </h2>
                        <p className='text-gray-600 text-center sm:text-start'>
                            We believe in treating our customers with respect. We always thrive
                            to cater to their needs.
                        </p>
                    </div>
                </div>
              
                <div className='w-full sm:w-[48%] lg:w-[45%] bg-white rounded-xl shadow-md border border-gray-200'>
                    <div className='p-6'>
                        <div className='flex mb-4'>
                            <div className='bg-yellow-200 p-4 rounded-md'>
                                <img src={img3} alt='img' />
                            </div>
                        </div>
                        <h2 className='text-lg font-semibold text-gray-800 text-center sm:text-start mb-2'>
                            Eco-friendly Practices
                        </h2>
                        <p className='text-gray-600 text-center sm:text-start'>
                            We are committed to sustainable and eco-friendly practices.
                        </p>
                    </div>
                </div>
              
                <div className='w-full sm:w-[48%] lg:w-[45%] bg-white rounded-xl shadow-md border border-gray-200'>
                    <div className='p-6'>
                        <div className='flex mb-4'>
                            <div className='bg-yellow-200 p-4 rounded-md'>
                                <img src={img4} alt='img' />
                            </div>
                        </div>
                        <h2 className='text-lg font-semibold text-gray-800 text-center sm:text-start mb-2'>
                            Chemical Safety
                        </h2>
                        <p className='text-gray-600 text-center sm:text-start'>
                            We ensure top-notch safety standards in handling chemicals.
                        </p>
                    </div>
                </div>
            </div>

            <div className='w-full lg:w-[40%] flex justify-center relative mt-8 lg:mt-0'>
                <img src={img} alt='img' className='w-[80%] lg:w-[90%]' />
                <div className='absolute bottom-4 left-4 md:left-10 text-white'>
                    <h3 className='text-lg md:text-4xl font-bold'>
                        250+ <br></br>Distributor <br></br>Franchises in Odisha
                    </h3>
                </div>
            </div>
        </div>
    );
};

export default WhyChooseUs;
