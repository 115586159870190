import React from 'react';
import img1 from '../../Assets/Home/1.png';
import img2 from '../../Assets/Home/2.png';
import img5 from '../../Assets/Home/5.png';
import img3 from '../../Assets/Home/3.png';
import img4 from '../../Assets/Home/4.png';

const PosterCards = () => {
  return (
    <React.Fragment>
      <div className='px-4 sm:px-8 md:px-16 flex flex-col md:flex-row gap-5 items-center justify-between'>
     
        <div className='space-y-5 w-full md:w-[33%]'>
          <img src={img1} alt='img rounded-md' className='rounded-md'/>
          <img src={img2} alt='img rounded-md' className='rounded-md'/>
        </div>
       
        <div className='w-full md:w-[33%]'>
          <img src={img5} alt='img rounded-md' className='rounded-md'/>
        </div>
      
        <div className='space-y-5 w-full md:w-[33%]'>
          <img src={img3} alt='img rounded-md' className='rounded-md'/>
          <img src={img4} alt='img rounded-md' className='rounded-md'/>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PosterCards;
