// import React from 'react';
// import img1 from '../../Assets/Aboutus/Mask1.png'
// import img2 from '../../Assets/Aboutus/mask2.png'
// import img3 from '../../Assets/Aboutus/mask3.png'

// const MissionVisionHistory = () => {
//     return (
//         <div className="bg-white py-12 px-6 md:px-16">
//             <div className="mb-12 flex gap-10">
//                 <h1 className="text-2xl md:text-4xl font-bold mb-4 justify-start">
//                     "Your Trusted Partner in Manufacturing"
//                 </h1>
//                 <p className="text-gray-600 text-sm md:text-base max-w-4xl mx-auto tracking-wide justify-end">
//                     We want to fulfill our missions while respecting planetary boundaries and assisting our stakeholders in their ecological transition, as expressed in our purpose. It conveys our desire for business success while also being environmentally conscious and compassionate toward people. With its deep knowledge of water and waste, its capacity for large-scale project management.
//                 </p>
//             </div>
//             <div className="flex gap-10">
//                 <div className="bg-gray-50 p-6 rounded-lg shadow-lg text-center">
//                     <div className="mb-4">
//                         <img src={img1} alt="Our Mission" className="mx-auto h-12 w-12" />
//                     </div>
//                     <h2 className="text-3xl font-semibold mb-2">Our Mission</h2>
//                     <p className="text-gray-600 tracking-wide">
//                         We manufacture healthy, delicious, high-quality beverages that will refresh the consumer. Keeping this purpose forever in mind, we design, develop, and market products that rejuvenate the community, inspire moments of optimism and happiness & creates value to make a difference.
//                     </p>
//                 </div>
//                 <div className="bg-gray-50 p-8 rounded-lg shadow-lg text-center">
//                     <div className="mb-4">
//                         <img src={img2} alt="Our Vision" className="mx-auto h-12 w-12" />
//                     </div>
//                     <h2 className="text-3xl font-semibold mb-2">Our Vision</h2>
//                     <p className="text-gray-600 tracking-wide">
//                         We are guided by our vision of becoming a leading beverage company and striving towards innovation to bring out the finest quality beverages for our consumers, building a network of devoted partners & sustaining and preserving mother nature.
//                     </p>
//                 </div>
//                 <div className="bg-gray-50 p-6 rounded-lg shadow-lg text-center">
//                     <div className="mb-4">
//                         <img src={img3} alt="Our History" className="mx-auto h-12 w-12" />
//                     </div>
//                     <h2 className="text-3xl font-semibold mb-2">Our History</h2>
//                     <p className="text-gray-600 tracking-wide">
//                         Commercial production started and the company incorporated on February 22, 2003, to producing packaged drinks exclusively. Starting in 2012, entering the soft drink industry and additionally in 2023, establishing an aseptic beverage line (juice in Tetra Pak).
//                     </p>
//                 </div>

//             </div>

//         </div>
//     );
// };

// export default MissionVisionHistory;



import React from 'react';
import img1 from '../../Assets/Aboutus/Mask1.png'
import img2 from '../../Assets/Aboutus/mask2.png'
import img3 from '../../Assets/Aboutus/mask3.png'

const MissionVisionHistory = () => {
    return (
        <div className="bg-white py-12 px-6 md:px-16">
            <div className="mb-12 flex flex-col md:flex-row md:gap-10 items-center">
                <h1 className="text-2xl md:text-4xl font-bold mb-4 md:mb-0 md:mr-6">
                    "Your Trusted Partner in Manufacturing"
                </h1>
                <p className="text-gray-600 text-sm md:text-base max-w-4xl mx-auto tracking-wide">
                    We want to fulfill our missions while respecting planetary boundaries and assisting our stakeholders in their ecological transition, as expressed in our purpose. It conveys our desire for business success while also being environmentally conscious and compassionate toward people. With its deep knowledge of water and waste, its capacity for large-scale project management.
                </p>
            </div>
            <div className="flex flex-col md:flex-row gap-10">
                <div className="bg-gray-50 p-6 rounded-lg shadow-lg text-center flex-1">
                    <div className="mb-4">
                        <img src={img1} alt="Our Mission" className="mx-auto h-12 w-12" />
                    </div>
                    <h2 className="text-2xl md:text-3xl font-semibold mb-2">Our Mission</h2>
                    <p className="text-gray-600 tracking-wide text-sm md:text-base">
                        We manufacture healthy, delicious, high-quality beverages that will refresh the consumer. Keeping this purpose forever in mind, we design, develop, and market products that rejuvenate the community, inspire moments of optimism and happiness & creates value to make a difference.
                    </p>
                </div>
                <div className="bg-gray-50 p-6 md:p-8 rounded-lg shadow-lg text-center flex-1">
                    <div className="mb-4">
                        <img src={img2} alt="Our Vision" className="mx-auto h-12 w-12" />
                    </div>
                    <h2 className="text-2xl md:text-3xl font-semibold mb-2">Our Vision</h2>
                    <p className="text-gray-600 tracking-wide text-sm md:text-base">
                        We are guided by our vision of becoming a leading beverage company and striving towards innovation to bring out the finest quality beverages for our consumers, building a network of devoted partners & sustaining and preserving mother nature.
                    </p>
                </div>
                <div className="bg-gray-50 p-6 rounded-lg shadow-lg text-center flex-1">
                    <div className="mb-4">
                        <img src={img3} alt="Our History" className="mx-auto h-12 w-12" />
                    </div>
                    <h2 className="text-2xl md:text-3xl font-semibold mb-2">Our History</h2>
                    <p className="text-gray-600 tracking-wide text-sm md:text-base">
                        Commercial production started and the company incorporated on February 22, 2003, to producing packaged drinks exclusively. Starting in 2012, entering the soft drink industry and additionally in 2023, establishing an aseptic beverage line (juice in Tetra Pak).
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MissionVisionHistory;
