import React from 'react';
import blog1 from '../../Assets/Home/blog1.png';
import blog2 from '../../Assets/Home/blog2.png';
import blog3 from '../../Assets/Home/blog3.png';
import { CgProfile } from "react-icons/cg";
import { CiCalendar } from "react-icons/ci";
import { AiOutlineMessage } from "react-icons/ai";
import { HiOutlineArrowSmRight } from "react-icons/hi";

const NewsAndBlogs = () => {
  return (
    <React.Fragment>
      <h3 className='text-center text-[#F9B401]'>News</h3>
      <h1 className='text-center text-2xl md:text-4xl lg:text-5xl'>Latest News & Blogs</h1>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7 px-2 sm:px-4 lg:px-16 mt-4'>
        <div className='p-2 sm:p-3 lg:p-5 border-4'>
          <div className='flex items-center justify-center'>
            <img src={blog1} alt='img' className='w-full h-auto p-2 sm:p-3' />
          </div>
          <div className='space-y-4 p-2 sm:p-3 lg:p-5'>
            <div className='flex flex-wrap space-x-4'>
              <div className='flex text-[#F5A93F] space-x-2 items-center'><CgProfile /><span className='text-black'>Kristin</span></div>
              <div className='flex text-[#F5A93F] space-x-2 items-center'><CiCalendar /><span className='text-black'>19 Dec, 2013</span></div>
              <div className='flex text-[#F5A93F] space-x-2 items-center'><AiOutlineMessage /><span className='text-black'>19 Comments</span></div>
            </div>
            <div>
              <h1 className='text-lg sm:text-xl'>Cras nisl dolor, accumsan et metus sit amet, vulputate condimentum dolor.</h1>
            </div>
            <div>
              <p className='text-sm sm:text-base'>Maecenas scelerisque, arcu quis tempus egestas, ligula diam molestie lectus, tincidunt malesuada arcu metus posuere metus.</p>
            </div>
            <button className='flex items-center justify-center border border-[#F5A93F] p-2 space-x-2'>
              Read more<span><HiOutlineArrowSmRight /></span>
            </button>
          </div>
        </div>
        <div className='p-2 sm:p-3 lg:p-5 border-4'>
          <div className='flex items-center justify-center'>
            <img src={blog2} alt='img' className='w-full h-auto p-2 sm:p-3' />
          </div>
          <div className='space-y-4 p-2 sm:p-3 lg:p-5'>
            <div className='flex flex-wrap space-x-4'>
              <div className='flex text-[#F5A93F] space-x-2 items-center'><CgProfile /><span className='text-black'>Kristin</span></div>
              <div className='flex text-[#F5A93F] space-x-2 items-center'><CiCalendar /><span className='text-black'>19 Dec, 2013</span></div>
              <div className='flex text-[#F5A93F] space-x-2 items-center'><AiOutlineMessage /><span className='text-black'>19 Comments</span></div>
            </div>
            <div>
              <h1 className='text-lg sm:text-xl'>Cras nisl dolor, accumsan et metus sit amet, vulputate condimentum dolor.</h1>
            </div>
            <div>
              <p className='text-sm sm:text-base'>Maecenas scelerisque, arcu quis tempus egestas, ligula diam molestie lectus, tincidunt malesuada arcu metus posuere metus.</p>
            </div>
            <button className='flex items-center justify-center border border-[#F5A93F] p-2 space-x-2'>
              Read more<span><HiOutlineArrowSmRight /></span>
            </button>
          </div>
        </div>
        <div className='p-2 sm:p-3 lg:p-5 border-4'>
          <div className='flex items-center justify-center'>
            <img src={blog3} alt='img' className='w-full h-auto p-2 sm:p-3' />
          </div>
          <div className='space-y-4 p-2 sm:p-3 lg:p-5'>
            <div className='flex flex-wrap space-x-4'>
              <div className='flex text-[#F5A93F] space-x-2 items-center'><CgProfile /><span className='text-black'>Kristin</span></div>
              <div className='flex text-[#F5A93F] space-x-2 items-center'><CiCalendar /><span className='text-black'>19 Dec, 2013</span></div>
              <div className='flex text-[#F5A93F] space-x-2 items-center'><AiOutlineMessage /><span className='text-black'>19 Comments</span></div>
            </div>
            <div>
              <h1 className='text-lg sm:text-xl'>Cras nisl dolor, accumsan et metus sit amet, vulputate condimentum dolor.</h1>
            </div>
            <div>
              <p className='text-sm sm:text-base'>Maecenas scelerisque, arcu quis tempus egestas, ligula diam molestie lectus, tincidunt malesuada arcu metus posuere metus.</p>
            </div>
            <button className='flex items-center justify-center border border-[#F5A93F] p-2 space-x-2'>
              Read more<span><HiOutlineArrowSmRight /></span>
            </button>
          </div>
        </div>
      
      </div>

    </React.Fragment>
  );
}

export default NewsAndBlogs;
