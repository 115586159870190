import React from 'react';
import gifSrc from '../../Assets/common/banner3.gif';

const Banner = () => {
    return (
        <div className="w-full overflow-hidden mb-6">
            <img
                className="w-full h-full object-contain"
                src={gifSrc}
                alt="Banner GIF"
            />
        </div>
    );
};

export default Banner;
