import React from 'react';
import banner from '../../Assets/Home/principal.png';
import img1 from '../../Assets/Home/principal1.png';
import img2 from '../../Assets/Home/principal2.png';
import img3 from '../../Assets/Home/principal3.png';
import img4 from '../../Assets/Home/principal4.png';
import img5 from '../../Assets/Home/principal5.png';
import img6 from '../../Assets/Home/principal6.png';

const OurPrinciple = () => {
  return (
    <React.Fragment>
      <div className='p-8' style={{
        background: "linear-gradient(90deg, #D1DEFF 0%, #F6FCFF 100%)",
      }}
      >
        <h1 className='text-center text-5xl'>Our Principle</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 md:w-[70%] mx-auto shadow-md bg-white mt-4 rounded-md">
          <div className="flex flex-col items-center p-6 border-r-4 border-b-4">
            <div className="text-5xl mb-4 text-green-500">
              <img className="mx-auto" src={img1} alt="Ethical Business" />
            </div>
            <h3 className="text-xl font-semibold">Ethical Business</h3>
          </div>
          <div className="flex flex-col items-center p-6 border-r-4 border-b-4">
            <div className="text-5xl mb-4 text-green-500">
              <img className="mx-auto" src={img2} alt="Decision Making" />
            </div>
            <h3 className="text-xl font-semibold">Decision Making</h3>
          </div>
          <div className="flex flex-col items-center p-6 border-b-4">
            <div className="text-5xl mb-4 text-green-500">
              <img className="mx-auto" src={img3} alt="Respect Human Rights" />
            </div>
            <h3 className="text-xl font-semibold">Respect Human Rights</h3>
          </div>
          <div className="flex flex-col items-center border-r-4 p-6">
            <div className="text-5xl mb-4 text-green-500">
              <img className="mx-auto" src={img4} alt="Risk Management" />
            </div>
            <h3 className="text-xl font-semibold">Risk Management</h3>
          </div>
          <div className="flex flex-col items-center p-6 border-r-4">
            <div className="text-5xl mb-4 text-green-500">
              <img className="mx-auto" src={img5} alt="Health & Safety" />
            </div>
            <h3 className="text-xl font-semibold">Health & Safety</h3>
          </div>
          <div className="flex flex-col items-center rounded-lg p-6">
            <div className="text-5xl mb-4 text-green-500">
              <img className="mx-auto" src={img6} alt="Good Environment" />
            </div>
            <h3 className="text-xl font-semibold">Good Environment</h3>
          </div>
        </div>
        <div
          className="h-80 w-full flex flex-col md:flex-row items-center px-4 md:px-20 mt-5"
          style={{
            backgroundImage: `url(${banner})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <div className="w-full md:w-[50%] text-white gap-4 text-center md:text-left">
            <h1 className="text-2xl sm:text-3xl md:text-5xl">Take a look at our numbers</h1>
            <p className="text-sm sm:text-base md:text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
          <div className="w-full md:w-[50%] text-white flex flex-col sm:flex-row justify-between mt-6 md:mt-0 space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="text-center sm:text-left">
              <h1 className="text-2xl sm:text-3xl md:text-5xl">2k+</h1>
              <p className="text-sm sm:text-base md:text-lg">Total Products</p>
            </div>
            <div className="text-center sm:text-left">
              <h1 className="text-2xl sm:text-3xl md:text-5xl">10k+</h1>
              <p className="text-sm sm:text-base md:text-lg">Happy Customers</p>
            </div>
            <div className="text-center sm:text-left">
              <h1 className="text-2xl sm:text-3xl md:text-5xl">21k+</h1>
              <p className="text-sm sm:text-base md:text-lg">Years of Experience</p>
            </div>
          </div>

        </div>

      </div>


    </React.Fragment>
  );
};

export default OurPrinciple;
