import React from "react";

import member1 from '../../Assets/Aboutus/member1.png'
import member2 from '../../Assets/Aboutus/member2.png'
import member3 from '../../Assets/Aboutus/Manager3.png'
import member4 from '../../Assets/Aboutus/Manager4.png'
const members = [
    {
        id: 1,
        name: "Sameer Pradhan",
        role: "Accountant",
        image: member1,
    },
    {
        id: 2,
        name: "Ashish Mishra",
        role: "Manager",
        image: member2,
    },

    {
        id: 3,
        name: "Sidhartha Ray",
        role: "Sales Officer",
        image: member3,
    },

    {
        id: 4,
        name: "Jenny Wilson",
        role: "UI/UX Designer",
        image: member4,
    },
];

const TeamMember = () => {
    return (
        // <div className="px-10 py-8 w-full ">
        //     <div className="flex flex-col items-center justify-center">
        //         <h1 className="font-semibold text-3xl md:text-4xl tracking-wide">
        //             Team Members
        //         </h1>
        //     </div>
        //     <div className="mt-6 flex flex-wrap items-center justify-center gap-6">
        //         {members.map(({ id, name, role, image }) => (
        //             <div
        //                 key={id}
        //                 className="bg-[#f8f7fc] rounded-lg overflow-hidden shadow w-[23%] flex-shrink-0"
        //             >
        //                 <img
        //                     src={image}
        //                     alt={name}
        //                     className="rounded-lg w-full object-cover"
        //                 />
        //                 <div className="p-4 text-start">
        //                     <h3 className="font-semibold text-lg">{name}</h3>
        //                     <p className="text-xl text-gray-500">{role}</p>
        //                 </div>
        //             </div>
        //         ))}
        //     </div>
        // </div>
        <div className="px-4 md:px-10 py-8 w-full">
            <div className="flex flex-col items-center justify-center">
                <h1 className="font-semibold text-2xl md:text-3xl lg:text-4xl tracking-wide">
                    Team Members
                </h1>
            </div>
            <div className="mt-6 flex flex-wrap items-center justify-center gap-4 md:gap-6">
                {members.map(({ id, name, role, image }) => (
                    <div
                        key={id}
                        className="bg-[#f8f7fc] rounded-lg overflow-hidden shadow w-full sm:w-[48%] md:w-[30%] lg:w-[23%] flex-shrink-0"
                    >
                        <img
                            src={image}
                            alt={name}
                            className="rounded-lg w-full object-cover"
                        />
                        <div className="p-4 text-start">
                            <h3 className="font-semibold text-lg">{name}</h3>
                            <p className="text-base md:text-lg text-gray-500">{role}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>

    );
};

export default TeamMember;
