import React from 'react'
import Banner from '../../Assets/ProductCard/BANNERS.png';
import img1 from "../../Assets/ProductCard/1.png";
import img2 from "../../Assets/ProductCard/2.png";
import img3 from "../../Assets/ProductCard/3.png";
import { FaStar } from "react-icons/fa6";
import img9 from "../../Assets/ProductCard/9.png";

const Banners = () => {
  const products = [
    {
      image: img1,
      name: "Premium water",
      volume: "1 L",
      price: "₹20",
      rating: 4.5,
    },
    {
      image: img2,
      name: "Ziyo Zeera",
      volume: "250 ml",
      price: "₹12.00",
      rating: 4.7,
    },
    {
      image: img3,
      name: "Ziyo Lime",
      volume: "250 ml",
      price: "₹12.00",
      rating: 4.2,
    },
    {
      image: img9,
      name: "Ziyo Orange",
      volume: "250 ml",
      price: "₹12.00",
      rating: 4.2,
    }
  ];

  return (
    <React.Fragment>
      <div>
        <img src={Banner} alt='img' className='w-[100%]' />
      </div>
      <div className="px-6 md:px-16 mt-8 mb-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          {products.map((product, index) => (
            <div
              key={index}
              className="bg-white shadow-md rounded-lg overflow-hidden relative p-4 hover:shadow-lg transition-shadow duration-300 ease-in-out"
            >
              {/* Product Image */}
              <div className="relative w-full">
                <div className="w-full bg-[#f6f6f6] rounded-lg overflow-hidden">
                  <img
                    src={product.image}
                    alt={product.name}
                    className="w-full h-80 object-contain transform transition-transform duration-300 hover:scale-105" // Increased height to 80
                  />
                </div>
                {/* Volume Tag */}
                <div className="absolute top-2 right-2 bg-[#e38b8b] text-white text-xs font-semibold px-2 py-1 rounded-lg">
                  {product.volume}
                </div>
              </div>

              {/* Product Details */}
              <div className="pt-4 text-center">
                <p className="flex justify-center items-center space-x-1 text-[#F5A93F] mb-2">
                  {[...Array(5)].map((_, i) => (
                    <FaStar key={i} size={14} />
                  ))}
                </p>
                <h2 className="text-md tracking-wide font-semibold">
                  {product.name}
                </h2>
                {/* <p className="text-xl font-bold text-blue-600">{product.price}</p> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Banners