import React from 'react';
import { FaStar } from "react-icons/fa6";
import img1 from "../../Assets/ProductCard/1.png";
import img2 from "../../Assets/ProductCard/2.png";
import img3 from "../../Assets/ProductCard/3.png";
import img4 from "../../Assets/ProductCard/4.png";
import img5 from "../../Assets/ProductCard/5.png";
import img6 from "../../Assets/ProductCard/6.png";
import img7 from "../../Assets/ProductCard/7.png";
import img8 from "../../Assets/ProductCard/8.png";


const products = [
    {
        image: img1,
        name: "Premium water",
        volume: "1 L",
        price: "₹20",
        rating: 4.5,
    },
    {
        image: img2,
        name: "Ziyo Zeera",
        volume: "250 ml",
        price: "₹12.00",
        rating: 4.7,
    },
    {
        image: img3,
        name: "Ziyo Lime",
        volume: "250 ml",
        price: "₹12.00",
        rating: 4.2,
    },
    {
        image: img4,
        name: "Mango Juice",
        volume: "250 ml",
        price: "₹12.00",
        rating: 4.1,
    },
    {
        image: img5,
        name: "Litchi",
        volume: "250 ml",
        price: "₹12.00",
        rating: 4.5,
    },
    {
        image: img6,
        name: "Jeera Drinks",
        volume: "250 ml",
        price: "₹12.00",
        rating: 4.7,
    },
    {
        image: img7,
        name: "Lime Drinks",
        volume: "250 ml",
        price: "₹12.00",
        rating: 4.2,
    },
    {
        image: img8,
        name: "Fingy",
        volume: "200 ml",
        price: "₹10.00",
        rating: 4.1,
    },
];

const Productlist = () => {
    return (
        <div className="px-6 md:px-16 mt-8 mb-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
                {products.map((product, index) => (
                    <div
                        key={index}
                        className="bg-white shadow-md rounded-lg overflow-hidden relative p-4 hover:shadow-lg transition-shadow duration-300 ease-in-out"
                    >
                      
                        <div className="relative w-full">
                            <div className="w-full bg-[#f6f6f6] rounded-lg overflow-hidden">
                                <img
                                    src={product.image}
                                    alt={product.name}
                                    className="w-full h-80 object-contain transform transition-transform duration-300 hover:scale-105" // Increased height to 80
                                />
                            </div>
                           
                            <div className="absolute top-2 right-2 bg-[#e38b8b] text-white text-xs font-semibold px-2 py-1 rounded-lg">
                                {product.volume}
                            </div>
                        </div>

                       
                        <div className="pt-4 text-center">
                            <p className="flex justify-center items-center space-x-1 text-[#F5A93F] mb-2">
                                {[...Array(5)].map((_, i) => (
                                    <FaStar key={i} size={14} />
                                ))}
                            </p>
                            <h2 className="text-md tracking-wide font-semibold">
                                {product.name}
                            </h2>
                         
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Productlist;
