import React from 'react'
import Vendors from '../../Assets/Home/Vendors.png'
import image from '../../Assets/Home/product.png'

const TopVender = () => {
  return (
    <React.Fragment>
      <h3 className='text-center text-[#F9B401] text-xl md:text-2xl'>Explore</h3>
      <h1 className='text-center text-3xl md:text-5xl'>Top Vendors</h1>
      <div className='px-4 md:px-16 flex flex-col md:flex-row mt-3 space-y-4 md:space-y-0 md:space-x-5'>
        <div className='w-full md:w-[50%]'>
          <img src={Vendors} alt='img' className='w-full h-auto' />
        </div>
        <div className='w-full md:w-[50%] space-y-4 md:space-y-8'>
          <div className='flex flex-col md:flex-row justify-between p-4 border-2 rounded-md'>
            <div>
              <h1 className='text-lg md:text-xl'>Shivam Enterprise</h1>
              <p className='text-sm md:text-base'>They started in 2019, distributing soft drinks, lemon-flavored</p>
            </div>
            <h1 className='text-sm md:text-lg'>Sales 300</h1>
          </div>
          <div className='flex flex-col md:flex-row justify-between p-4 border-2 rounded-md'>
            <div>
              <h1 className='text-lg md:text-xl'>Ghanshyam Enterprises</h1>
              <p className='text-sm md:text-base'> A wholesale distributor of colds juices serving various regions in India​.
</p>
            </div>
            <h1 className='text-sm md:text-lg'>Sales 300</h1>
          </div>
          <div className='flex flex-col md:flex-row justify-between p-4 border-2 rounded-md'>
            <div>
              <h1 className='text-lg md:text-xl'>Arc Foods & Beverages </h1>
              <p className='text-sm md:text-base'>Supplies a variety of soft drinks, including flavored to retailers across India​</p>
            </div>
            <h1 className='text-sm md:text-lg'>Sales 300</h1>
          </div>
          <div className='flex flex-col md:flex-row justify-between p-4 border-2 rounded-md'>
            <div>
              <h1 className='text-lg md:text-xl'>VSD Corporation </h1>
              <p className='text-sm md:text-base'> A longstanding player in the market since 1982 cold drinks soft drinks.</p>
            </div>
            <h1 className='text-sm md:text-lg'>Sales 300</h1>
          </div>
        </div>
      </div>
      <div className="h-[300px] md:h-[400px] flex items-center px-4 md:px-16 mt-6"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}>
        <div className='space-y-2 md:space-y-4 flex flex-col'>
          <h2 className='text-[#F9B401] text-lg md:text-2xl'>Exciting Product</h2>
          <h1 className='text-2xl md:text-5xl tracking-wide'>Packed Drinking Water</h1>
          <p className='text-sm md:text-base'>Modern Packing with skin Lable on every Bottle  Balanced TDS,<br /> PH other parameters which meet as per BIS & FSSAI</p>
          <button className='p-2 bg-[#EE5858] w-[70%] md:w-[30%] rounded-md'>Book now</button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TopVender