import React from 'react'

const DropUsLine = () => {
    return (
        <form className="pt-6 pb-8 mb-4 px-6 md:px-16">
            <h2 className="font-bold mb-6 text-start text-5xl">Drop Us a Line</h2>
            <p className="text-sm text-start mb-6 tracking-wider">Your email address will not be published. Required fields are marked *</p>


            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <input
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        placeholder="Enter Your Name*"
                    />
                </div>
                <div className="w-full md:w-1/2 px-3">
                    <input
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="email"
                        placeholder="Enter Email Address*"
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <div className="relative">
                        <select
                            className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                            <option>Select Property Type</option>
                            <option>Commercial</option>
                            <option>Residential</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-1/2 px-3">
                    <input
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        placeholder="Enter Phone Number"
                    />
                </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                    <textarea
                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        placeholder="Enter Your Message here"
                        rows="4"
                    ></textarea>
                </div>
            </div>

           
            <div className="flex justify-start">
                <button
                    type="button"
                    className="bg-[#EBC80C] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    POST A COMMENT
                </button>
            </div>
        </form>
    )
}

export default DropUsLine