// import React from 'react'
// import logo from '../../Assets/logos/logo.svg';
// import play from '../../Assets/Aboutus/paystore.png';
// import apple from '../../Assets/Aboutus/applestore.png';
// import { FaFacebook } from "react-icons/fa";
// import { FiInstagram } from "react-icons/fi";
// import { BsYoutube } from "react-icons/bs";
// import { FaTwitter } from "react-icons/fa";
// const Footer = () => {
//     const currentYear = new Date().getFullYear();

//     return (
//         <React.Fragment>
//             <div className="flex px-16 bg-[#191C1F] text-white p-4">
//                 <div className="w-[25%] flex flex-col items-center gap-2">
//                     <img src={logo} alt="Logo" className="w-[40%] mb-4" />
//                     <p className="text-sm text-[#929FA5]">
//                         Commercial Production Started And The Company Incorporated On February 22, 2003.
//                     </p>
//                     <div className="flex space-x-4 mt-4">
//                         <div className="text-gray-400 hover:text-white">
//                             <FaFacebook />
//                         </div>
//                         <div className="text-gray-400 hover:text-white">
//                             <FaTwitter/>
//                         </div>
//                         <div className="text-gray-400 hover:text-white">
//                             <FiInstagram />

//                         </div>
//                         <div className="text-gray-400 hover:text-white">
//                            <BsYoutube/>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="w-[25%] flex flex-col items-center">
//                     <h4 className="text-lg mb-4 text-center">Quick Links</h4>
//                     <div className="space-y-2 text-[#929FA5]">
//                         <div>Shop Product</div>
//                         <div>Shopping Cart</div>
//                         <div>Wishlist</div>
//                         <div>Compare</div>
//                         <div>Track Order</div>
//                         <div>Customer Help</div>
//                         <div>About Us</div>
//                     </div>
//                 </div>
//                 <div className="w-[25%] flex flex-col">
//                     <h4 className="text-lg mb-4 ">Contact Info</h4>
//                     <div className='text-[#929FA5]'>
//                         <p>06670-231347</p>
//                         <p>Chancher-766011, Post-Utkela, Dist-Kalahandi</p>
//                         <p>baisalyaqua@gmail.com</p>
//                     </div>
//                 </div>
//                 <div className="w-[25%] flex flex-col items-center justify-center text-center gap-9">
//                     <div className="hover:text-white">
//                         <img src={play} alt="Google Play" className="w-[100%]" />
//                     </div>
//                     <div className="hover:text-white">
//                         <img src={apple} alt="App Store" className="w-[100%]" />
//                     </div>
//                 </div>
//             </div>

//             <footer className="footer bg-gray-800 shadow text-white px-5 py-4">
//                 <div className="mx-auto">
//                     <div className="flex justify-between">
//                         <div className="md:w-1/2 text-center md:text-left mb-4 md:mb-0">
//                             {currentYear} © <span className=''>P & M</span>.
//                         </div>
//                         <div className="md:w-1/2 text-center md:text-right">
//                             <div className="text-sm d-none d-sm-block">
//                                 Design & Develop by
//                                 <a href="#" className="ms-1 underline">
//                                     Web-Bocket
//                                 </a>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </footer>
//         </React.Fragment>
//     )
// }

// export default Footer


// import React from 'react';
// import logo from '../../Assets/logos/logo.svg';
// import play from '../../Assets/Aboutus/paystore.png';
// import apple from '../../Assets/Aboutus/applestore.png';
// import { FaFacebook } from "react-icons/fa";
// import { FiInstagram } from "react-icons/fi";
// import { BsYoutube } from "react-icons/bs";
// import { FaTwitter } from "react-icons/fa";

// const Footer = () => {
//     const currentYear = new Date().getFullYear();

//     return (
//         <React.Fragment>
//             <div className="flex flex-col md:flex-row px-4 md:px-8 lg:px-16 bg-[#191C1F] text-white py-8 space-x-16">
                
//                 <div className="w-full md:w-[25%] flex flex-col items-center md:items-start gap-4 mb-6 md:mb-0">
//                     <img src={logo} alt="Logo" className="w-[40%] mb-4" />
//                     <p className="text-sm text-[#929FA5] text-center md:text-left">
//                         Commercial Production Started And The Company Incorporated On February 22, 2003.
//                     </p>
//                     <div className="flex space-x-4 mt-4">
//                         <div className="text-gray-400 hover:text-white">
//                             <FaFacebook />
//                         </div>
//                         <div className="text-gray-400 hover:text-white">
//                             <FaTwitter />
//                         </div>
//                         <div className="text-gray-400 hover:text-white">
//                             <FiInstagram />
//                         </div>
//                         <div className="text-gray-400 hover:text-white">
//                             <BsYoutube />
//                         </div>
//                     </div>
//                 </div>

              
//                 <div className="w-full md:w-[25%] flex flex-col items-center md:items-start mb-6 md:mb-0">
//                     <h4 className="text-lg mb-4">Quick Links</h4>
//                     <div className="space-y-2 text-[#929FA5] text-center md:text-left">
//                         <div>Shop Product</div>
//                         <div>Shopping Cart</div>
//                         <div>Wishlist</div>
//                         <div>Compare</div>
//                         <div>Track Order</div>
//                         <div>Customer Help</div>
//                         <div>About Us</div>
//                     </div>
//                 </div>

                
//                 <div className="w-full md:w-[25%] flex flex-col items-center md:items-start mb-6 md:mb-0">
//                     <h4 className="text-lg mb-4">Contact Info</h4>
//                     <div className="text-[#929FA5] text-center md:text-left">
//                         <p>06670-231347</p>
//                         <p>Chancher-766011, Post-Utkela, Dist-Kalahandi</p>
//                         <p>baisalyaqua@gmail.com</p>
//                     </div>
//                 </div>

                
//                 <div className="w-full md:w-[25%] flex flex-col items-center gap-6">
//                     <div className="hover:text-white">
//                         <img src={play} alt="Google Play" className="w-[60%] md:w-[100%]" />
//                     </div>
//                     <div className="hover:text-white">
//                         <img src={apple} alt="App Store" className="w-[60%] md:w-[100%]" />
//                     </div>
//                 </div>
//             </div>

         
//             <footer className="footer bg-gray-800 text-white px-5 py-4">
//                 <div className="container mx-auto">
//                     <div className="flex flex-col md:flex-row justify-between text-center md:text-left">
//                         <div className="mb-4 md:mb-0">
//                             {currentYear} © <span>P & M</span>.
//                         </div>
//                         <div className="text-sm">
//                             Design & Develop by 
//                             <a href="#" className="ml-1 underline">Web-Bocket</a>
//                         </div>
//                     </div>
//                 </div>
//             </footer>
//         </React.Fragment>
//     );
// }

// export default Footer;


import React from 'react';
import logo from '../../Assets/logos/logo.svg';
import play from '../../Assets/Aboutus/paystore.png';
import apple from '../../Assets/Aboutus/applestore.png';
import { FaFacebook, FaTwitter } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { BsYoutube } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
const Footer = () => {
    const currentYear = new Date().getFullYear();
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <div className="flex flex-col lg:flex-row lg:space-x-16 px-4 md:px-8 lg:px-16 bg-[#191C1F] text-white py-8 space-y-8 lg:space-y-0">
                
            
                <div className="w-full lg:w-[25%] flex flex-col items-center lg:items-start text-center lg:text-left gap-4">
                    <img src={logo} alt="Logo" className="w-[40%] mb-4" />
                    <p className="text-sm text-[#929FA5]">
                        Commercial Production Started And The Company Incorporated On February 22, 2003.
                    </p>
                    <div className="flex space-x-4 mt-4">
                        <FaFacebook className="text-gray-400 hover:text-white" />
                        <FaTwitter className="text-gray-400 hover:text-white" />
                        <FiInstagram className="text-gray-400 hover:text-white" />
                        <BsYoutube className="text-gray-400 hover:text-white" />
                    </div>
                </div>

               
                <div className="w-full lg:w-[25%] flex flex-col items-center lg:items-start gap-4">
                    <h4 className="text-lg mb-2">Quick Links</h4>
                    <div className="space-y-2 text-[#929FA5]">
                        <div onClick={() => { navigate('/'); }}>Home</div>
                        <div onClick={() => { navigate('/about'); }}>About Us</div>
                        <div onClick={() => { navigate('/products'); }}>Poducts</div>
                        <div onClick={() => { navigate('/contact'); }}>Contact</div>
                    </div>
                </div>

             
                <div className="w-full lg:w-[25%] flex flex-col items-center lg:items-start gap-4">
                    <h4 className="text-lg mb-2">Contact Info</h4>
                    <div className="text-[#929FA5]">
                        <p>06670-231347</p>
                        <p>Chancher-766011, Post-Utkela, Dist-Kalahandi</p>
                        <p>baisalyaqua@gmail.com</p>
                    </div>
                </div>

                
                <div className="w-full lg:w-[25%] flex flex-col items-center gap-4">
                    <img src={play} alt="Google Play" className="w-[60%] md:w-[50%] lg:w-[60%]" />
                    <img src={apple} alt="App Store" className="w-[60%] md:w-[50%] lg:w-[60%]" />
                </div>
            </div>

         
            <footer className="bg-gray-800 text-white px-5 py-4">
                <div className="container mx-auto">
                    <div className="flex flex-col md:flex-row justify-between items-center text-center md:text-left">
                        <div className="mb-4 md:mb-0">
                            {currentYear} © <span>P & M</span>.
                        </div>
                        <div className="text-sm">
                            Design & Develop by
                            <a href="#" className="ml-1 underline">Web-Bocket</a>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    );
}

export default Footer;
