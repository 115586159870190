import React from 'react';
import sampleImage from '../../Assets/common/Untitled (500 x 300 px).png';
import logo from '../../Assets/logos/logo.svg';

const SimpleBanner = ({ image, name, path }) => {
    return (
        <div
            className="shadow-md w-full h-[150px] md:h-[300px] bg-gradient-to-r from-[#ffa14f] to-[#ffdb58] flex items-center"
            style={{
                backgroundColor: '#f5f5f5',
            }}
        >
            {/* Left Section: Image */}
            <div className="hidden md:block">
                <img
                    src={sampleImage}
                    alt="Left Side"
                    className="h-[300px] w-auto object-cover"
                />
            </div>

            {/* Middle Section: Page Name and Path */}
            <div className="flex-1 flex flex-col justify-center items-center text-center text-[#ffffff]">
                <h1
                    style={{ fontFamily: 'cursive' }}
                    className="text-2xl md:text-4xl font-bold tracking-wider capitalize">
                    {name}
                </h1>
                <p className="mt-2 capitalize text-sm md:text-lg tracking-wider">
                    {path}
                </p>
            </div>

            {/* Right Section: Logo */}
            <div className="flex-1 hidden md:flex justify-center items-center">
                <img
                    src={logo}
                    alt="Logo"
                    className="h-16 md:h-36 object-contain"
                />
            </div>
        </div>
    );
};

export default SimpleBanner;















// import React from 'react';
// import banner from '../../Assets/common/banner2.png';

// const SimpleBanner = ({ image, name, path }) => {
//     return (
//         <div
//             className="h-[17.7rem] w-full flex items-center justify-center px-4 md:px-16"
//             style={{
//                 backgroundImage: `url(${banner})`,
//                 backgroundSize: "contain", // You can try "contain" if it fits better
//                 backgroundRepeat: "no-repeat",
//                 backgroundPosition: "center center", // Ensures image stays centered
//             }}
//         >
//             <div className="flex flex-col items-center text-center text-[#ffffff]">
//                 {/* Page Name and Breadcrumb */}
//                 <h1
//                     style={{ fontFamily: 'cursive' }}
//                     className="text-2xl md:text-4xl font-bold tracking-wider capitalize">
//                     {name}
//                 </h1>
//                 <p className="mt-2 capitalize text-sm md:text-lg tracking-wider">
//                     {path}
//                 </p>
//             </div>
//         </div>
//     );
// }

// export default SimpleBanner;
