import React from 'react'
import SimpleBanner from '../Common/SimpleBanner'
import Contactbanar from '../../Assets/ProductCard/ContactBanner.png'
import MissionVisionHistory from '../../Components/AboutUs/MissionVisionHistory'
import TeamMember from '../../Components/AboutUs/TeamMember'
import WhyChooseUs from '../../Components/AboutUs/WhyChooseUs'
import Founder from '../../Components/AboutUs/Founder'
import Awards from '../../Components/AboutUs/Awards'
import Newsletter from '../../Components/Contact/Newsletter';
import QuoteCard from '../../Components/AboutUs/QuoteCard'
import Product from '../../Components/AboutUs/Product'
const About = () => {
  return (
    <div>
       <SimpleBanner
        image={Contactbanar}
        name="About Us"
        path="Home / About Us"
      />
      <QuoteCard/>
      <Product/>
      <MissionVisionHistory/>
      <WhyChooseUs/>
      <Founder/>
      <TeamMember/>
      <Awards/>
      <Newsletter/>
    </div>
  )
}
export default About