// import React from 'react'
// import img1 from '../../Assets/Aboutus/founder1.png'
// import img2 from '../../Assets/Aboutus/Frame1.png'
// import img3 from '../../Assets/Aboutus/fonder2.png'
// import img4 from '../../Assets/Aboutus/Frame2.png'
// const Founder = () => {
//     return (
//         <div className='space-y-12 mt-6'>
//             <div className='flex px-16 gap-20'>
//                 <div>
//                     <img src={img1} alt='img' />
//                 </div>
//                 <div>
//                     <img src={img2} alt='img'/>
//                 </div>
//             </div>
//             <div className='flex px-16 gap-20'>
//             <div>
//                     <img src={img4} alt='img'/>
//                 </div>
//                 <div>
//                     <img src={img3} alt='img' />
//                 </div>
                
//             </div>
//         </div>
//     )
// }

// export default Founder


import React from 'react'
import img2 from '../../Assets/Aboutus/Frame 427321511.png'
import img4 from '../../Assets/Aboutus/Frame 427321512.png'

const Founder = () => {
    return (
        <div className='space-y-6 mt-6 px-4 md:px-16'>
            <img 
                src={img2} 
                alt='Founder Image 1' 
                className='w-full object-cover'
            />
            <img 
                src={img4} 
                alt='Founder Image 2' 
                className='w-full object-cover'
            />
        </div>
    )
}

export default Founder

