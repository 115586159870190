import React from 'react'
import Productlist from '../../Components/Products/Productlist'
import Banners from '../../Components/Products/Banners'
import SimpleBanner from '../Common/SimpleBanner'
import Contactbanar from '../../Assets/ProductCard/ContactBanner.png'
const Products = () => {
  return (
    <>
     <SimpleBanner
        image={Contactbanar}
        name="Products"
        path="Home / Products"
      />
      <Productlist/>
      <Banners/>
    </>
  )
}

export default Products
