import React from 'react'
import img1 from "../../Assets/ProductCard/1.png";
import img2 from "../../Assets/ProductCard/2.png";
import img3 from "../../Assets/ProductCard/3.png";
import img4 from "../../Assets/ProductCard/4.png";
import { FaStar } from "react-icons/fa6";
const products = [
  {
    image: img1,
    name: "Premium water",
    volume: "1 L",
    price: "₹20",
    rating: 4.5,
  },
  {
    image: img2,
    name: "Ziyo Zeera",
    volume: "250 ml",
    price: "₹12.00",
    rating: 4.7,
  },
  {
    image: img3,
    name: "Ziyo Lime",
    volume: "250 ml",
    price: "₹12.00",
    rating: 4.2,
  },
  {
    image: img4,
    name: "Mango Juice",
    volume: "250 ml",
    price: "₹12.00",
    rating: 4.1,
  },
];

const ProductCards = () => {
  return (
    <React.Fragment>
      <div className="px-4 md:px-16 mt-8 mb-8">
        <div className='flex flex-col md:flex-row md:px-9 justify-between'>
          <div className="mb-4 md:mb-0">
            <h3 className='text-xl md:text-2xl text-[#F9B401]'>Products</h3>
            <h1 className='text-3xl md:text-5xl'>Deals of the day</h1>
          </div>
          <div className='text-lg md:text-xl'>
            975 Days 23 : 32 : 40
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 md:px-16 px-4">
        {products.map((product, index) => (
          <div
            key={index}
            className="bg-white shadow-md rounded-lg overflow-hidden relative p-4 hover:shadow-lg transition-shadow duration-300 ease-in-out"
          >

            <div className="relative w-full">
              <div className="w-full bg-[#f6f6f6] rounded-lg overflow-hidden">
                <img
                  src={product.image}
                  alt={product.name}
                  className="w-full h-80 object-contain transform transition-transform duration-300 hover:scale-105" // Increased height to 80
                />
              </div>

              <div className="absolute top-2 right-2 bg-[#e38b8b] text-white text-xs font-semibold px-2 py-1 rounded-lg">
                {product.volume}
              </div>
            </div>


            <div className="pt-4 text-center">
              <p className="flex justify-center items-center space-x-1 text-[#F5A93F] mb-2">
                {[...Array(5)].map((_, i) => (
                  <FaStar key={i} size={14} />
                ))}
              </p>
              <h2 className="text-md tracking-wide font-semibold">
                {product.name}
              </h2>

            </div>
          </div>
        ))}
      </div>
    
    </React.Fragment >
  )
}

export default ProductCards