import React from 'react';

const Newsletter = () => {
    return (
        <div className="mx-auto text-center mt-10 px-4">
            <h2 className="text-3xl md:text-5xl font-bold mb-4">Subscribe to Our Newsletter</h2>
            <p className="text-[#1A1A1A] mb-6 text-sm md:text-base">
                Praesent fringilla erat a lacinia egestas. Donec vehicula tempor libero et cursus.<br />
                Donec non quam urna. Quisque vitae porta ipsum.
            </p>
            <form className="flex flex-col md:flex-row justify-center items-center bg-white shadow-md p-6 md:p-8">
                <input
                    type="email"
                    placeholder="Email address"
                    className="border border-gray-300 rounded-t-md md:rounded-l-md md:rounded-r-none px-4 py-3 focus:outline-none w-full max-w-xs md:max-w-sm mb-4 md:mb-0"
                />
                <button
                    type="submit"
                    className="bg-[#EBC80C] rounded-b-md md:rounded-r-md md:rounded-l-none focus:outline-none px-4 py-3 w-full md:w-auto"
                >
                    SUBSCRIBE &rarr;
                </button>
            </form>
        </div>
    );
};

export default Newsletter;
