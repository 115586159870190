import React from 'react';
import Banner from '../../Components/Home/Banner';
import PosterCards from '../../Components/Home/PosterCards';
import ProductCards from '../../Components/Home/ProductCards';
import OurPrinciple from '../../Components/Home/OurPrinciple';
import ProductsList from '../../Components/Home/ProductsList';
import TopVender from '../../Components/Home/TopVender';
import NewsAndBlogs from '../../Components/Home/NewsAndBlogs';
import Newsletter from '../../Components/Contact/Newsletter';

const Home = () => {
  return (
    <React.Fragment>
      <Banner />
      <PosterCards />
      <ProductCards />
      <OurPrinciple />
      <ProductsList />
      <TopVender />
      <NewsAndBlogs />
      <Newsletter/>
    </React.Fragment>
  )
}

export default Home