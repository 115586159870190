import React from 'react';
import TopNav from '../Navbar/TopNav';
import BottomNav from '../Navbar/BottomNav';

const Navbar = () => {

  return (
    <React.Fragment>
      <div className='shadow-lg'>
        <TopNav />
        <BottomNav />
      </div>
    </React.Fragment>
  )
}

export default Navbar
